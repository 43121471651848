














































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DetailSummary from '../../../components/DetailSummary/index.vue'
import controller from '@/app/ui/controllers/RewardAndPunishmentController'
import {
  CourierRating,
  ProgramDetail,
  ProgramLevelBonus,
  ProgramLevelBonusData,
  ProgramLevelBonusDay,
  ProgramLevelBonusFee,
  ProgramLevelDetail,
  RejectIgnoreSuccessPickup,
  ProgramLevelPenalty,
  ProgramLevelPenaltyData,
} from '@/domain/entities/RewardAndPunishment'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LevelTextInput from '../../../components/LevelTextInput/index.vue'
import { IOptions } from '@/data/infrastructures/misc/interfaces/rewardAndPunishment'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  required,
  maxLength,
  minLength,
  requiredIf,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import CheckBox from '../../../components/CheckBox/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import ModalConfirm from '../../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../../components/Modals/ModalSuccess/index.vue'
import { CreateProgramLevelBonusRewardAndPunishmentRequest } from '@/data/payload/api/RewardAndPunishmentRequest'

interface IForm {
  level: {
    operator?: IOptions
    name: string
  }
  avgCourierRate: {
    operator?: IOptions
    rate: number | null
  }
  ignoreAndRejectCourierRate: {
    operator?: IOptions
    percentage: number | null
  }
  successPickupRate: {
    operator?: IOptions
    percentage: number | null
  }
  bonus: {
    weekday: IBonusForm
    weekend: IBonusForm
  }
  penalty: IPenaltyForm
}

interface IPenaltyForm {
  isFreeze: boolean
  list: IPenaltyList[]
}

interface IPenaltyList {
  penaltyId: number | null
  fieldId: number
  isAddButton: boolean
  isValidate: boolean
  timePickupMinimum: number | null
  timePickupMaximum: number | null
  penaltyAmount: number | null
  isTimePickupMinimumDisabled: boolean
}

interface IBonusFee {
  isValidate: boolean
  bonusFeeId: number | null
  fieldId: number
  fee: number | null
  quota: number | null
  isUnlimited: boolean
}

interface IBonusFormData {
  isValidate: boolean
  isAddButton: boolean
  bonusId: number | null
  fieldId: number
  isRandomize: boolean
  timePickupMinimum: number | null
  timePickupMaximum: number | null
  isTimePickupMinimumDisabled: boolean
  bonusFee: IBonusFee[]
}

interface IBonusForm {
  isPickup: boolean
  isDelivery: boolean
  withdrawalFee: number | null
  data: IBonusFormData[]
}

interface IValidations {
  [k: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    DetailSummary,
    TextInput,
    DropdownSelect,
    LevelTextInput,
    Button,
    LoadingOverlay,
    Tabs,
    CheckBox,
    ModalConfirm,
    ModalSuccess,
    AddIcon,
  },
})
export default class RewardAndPunishmentSetupLevel extends Vue {
  controller = controller

  detailData: ProgramLevelDetail = new ProgramLevelDetail()

  isConfirmModalVisible = false
  isSuccessModalVisible = false

  operatorOptions: IOptions[] = [
    {
      label: '>= (Lebih sama dengan)',
      value: 'gte',
    },
    {
      label: '= (Sama dengan)',
      value: 'eq',
    },
    {
      label: '<= (Kurang sama dengan)',
      value: 'lte',
    },
  ]

  itemTabs = [
    {
      key: 'bonus',
      value: 'bonus',
      label: 'Bonus',
    },
    {
      key: 'penalty',
      value: 'penalty',
      label: 'Penalti',
    },
  ]

  bonusItemTabs = [
    {
      key: 'weekday',
      value: 'weekday',
      label: 'Hari Kerja',
    },
    {
      key: 'weekend',
      value: 'weekend',
      label: 'Hari Libur (Minggu & Libur Nasional)',
    },
  ]

  currentTab = this.itemTabs[0].value
  bonusCurrentTab = this.bonusItemTabs[0].value

  form: IForm = {
    level: {
      operator: undefined,
      name: '',
    },
    avgCourierRate: {
      operator: this.operatorOptions[0],
      rate: null,
    },
    ignoreAndRejectCourierRate: {
      operator: this.operatorOptions[2],
      percentage: null,
    },
    successPickupRate: {
      operator: this.operatorOptions[0],
      percentage: null,
    },
    bonus: {
      weekday: {
        isDelivery: false,
        isPickup: true,
        withdrawalFee: null,
        data: [
          {
            isValidate: true,
            isAddButton: false,
            bonusId: null,
            fieldId: new Date().getTime() + Math.random(),
            isRandomize: false,
            timePickupMaximum: null,
            timePickupMinimum: null,
            isTimePickupMinimumDisabled: false,
            bonusFee: [
              {
                isValidate: true,
                bonusFeeId: null,
                fieldId: new Date().getTime() + Math.random(),
                fee: null,
                isUnlimited: false,
                quota: null,
              },
            ],
          },
          {
            isValidate: false,
            isAddButton: true,
            bonusId: null,
            fieldId: new Date().getTime() + Math.random(),
            isRandomize: false,
            timePickupMaximum: null,
            timePickupMinimum: null,
            isTimePickupMinimumDisabled: false,
            bonusFee: [
              {
                isValidate: false,
                bonusFeeId: null,
                fieldId: new Date().getTime() + Math.random(),
                fee: null,
                isUnlimited: false,
                quota: null,
              },
            ],
          },
        ],
      },
      weekend: {
        isDelivery: false,
        isPickup: true,
        withdrawalFee: null,
        data: [
          {
            isValidate: true,
            isAddButton: false,
            bonusId: null,
            fieldId: new Date().getTime() + Math.random(),
            isRandomize: false,
            timePickupMaximum: null,
            timePickupMinimum: null,
            isTimePickupMinimumDisabled: false,
            bonusFee: [
              {
                isValidate: true,
                bonusFeeId: null,
                fieldId: new Date().getTime() + Math.random(),
                fee: null,
                isUnlimited: false,
                quota: null,
              },
            ],
          },
          {
            isValidate: false,
            isAddButton: true,
            bonusId: null,
            fieldId: new Date().getTime() + Math.random(),
            isRandomize: false,
            timePickupMaximum: null,
            timePickupMinimum: null,
            isTimePickupMinimumDisabled: false,
            bonusFee: [
              {
                isValidate: false,
                bonusFeeId: null,
                fieldId: new Date().getTime() + Math.random(),
                fee: null,
                isUnlimited: false,
                quota: null,
              },
            ],
          },
        ],
      },
    },
    penalty: {
      isFreeze: false,
      list: [
        {
          isAddButton: false,
          isValidate: true,
          timePickupMaximum: null,
          timePickupMinimum: null,
          penaltyId: null,
          fieldId: new Date().getTime() + Math.random(),
          penaltyAmount: null,
          isTimePickupMinimumDisabled: false,
        },
        {
          isAddButton: true,
          isValidate: false,
          penaltyAmount: null,
          penaltyId: null,
          fieldId: new Date().getTime() + Math.random(),
          timePickupMaximum: null,
          timePickupMinimum: null,
          isTimePickupMinimumDisabled: false,
        },
      ],
    },
  }

  created(): void {
    this.fetchProgramDetail()
  }

  @Validations()
  validations(): IValidations {
    return {
      form: {
        level: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100),
          },
        },
        avgCourierRate: {
          operator: { required },
          rate: { required, minValue: minValue(1), maxValue: maxValue(5) },
        },
        ignoreAndRejectCourierRate: {
          operator: { required },
          percentage: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        successPickupRate: {
          operator: { required },
          percentage: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        bonus: {
          weekday: {
            withdrawalFee: { required },
            data: {
              $each: {
                timePickupMaximum: {
                  required: requiredIf(function(field) {
                    return field.isValidate
                  }),
                  lessThanMinimum: (
                    val: number,
                    vm: { timePickupMinimum: number }
                  ) => {
                    if (val && vm.timePickupMinimum) {
                      return val > vm.timePickupMinimum
                    }
                    return true
                  },
                },
                timePickupMinimum: {
                  required: requiredIf(function(field) {
                    return field.isValidate
                  }),
                  isHeavierPrevRow: (value: number, field: IBonusFormData) => {
                    if (!field.isAddButton) {
                      const currentIdx = this.form.bonus.weekday.data.findIndex(
                        bonus => bonus.fieldId === field.fieldId
                      )

                      if (currentIdx > 0) {
                        if (
                          this.form.bonus.weekday.data[currentIdx - 1]
                            .timePickupMaximum &&
                          value
                        ) {
                          return (
                            <number>(
                              this.form.bonus.weekday.data[currentIdx - 1]
                                .timePickupMaximum
                            ) < value
                          )
                        }
                      }
                    }
                    return true
                  },
                },
                bonusFee: {
                  $each: {
                    fee: {
                      required: requiredIf(function(field) {
                        return field.isValidate
                      }),
                    },
                    quota: {
                      required: requiredIf(function(field) {
                        return !field.isUnlimited && field.isValidate
                      }),
                    },
                  },
                },
              },
            },
          },
          weekend: {
            withdrawalFee: { required },
            data: {
              $each: {
                timePickupMaximum: {
                  required: requiredIf(function(field) {
                    return field.isValidate
                  }),
                  lessThanMinimum: (
                    val: number,
                    vm: { timePickupMinimum: number }
                  ) => {
                    if (val && vm.timePickupMinimum) {
                      return val > vm.timePickupMinimum
                    }
                    return true
                  },
                },
                timePickupMinimum: {
                  required: requiredIf(function(field) {
                    return field.isValidate
                  }),
                  isHeavierPrevRow: (value: number, field: IBonusFormData) => {
                    if (!field.isAddButton) {
                      const currentIdx = this.form.bonus.weekend.data.findIndex(
                        bonus => bonus.fieldId === field.fieldId
                      )

                      if (currentIdx > 0) {
                        if (
                          this.form.bonus.weekend.data[currentIdx - 1]
                            .timePickupMaximum &&
                          value
                        ) {
                          return (
                            <number>(
                              this.form.bonus.weekend.data[currentIdx - 1]
                                .timePickupMaximum
                            ) < value
                          )
                        }
                      }
                    }
                    return true
                  },
                },
                bonusFee: {
                  $each: {
                    fee: {
                      required: requiredIf(function(field) {
                        return field.isValidate
                      }),
                    },
                    quota: {
                      required: requiredIf(function(field) {
                        return !field.isUnlimited && field.isValidate
                      }),
                    },
                  },
                },
              },
            },
          },
        },
        penalty: {
          list: {
            $each: {
              timePickupMaximum: {
                required: requiredIf(function(field) {
                  return field.isValidate
                }),
                lessThanMinimum: (
                  val: number,
                  vm: { timePickupMinimum: number }
                ) => {
                  if (val && vm.timePickupMinimum) {
                    return val > vm.timePickupMinimum
                  }
                  return true
                },
              },
              timePickupMinimum: {
                required: requiredIf(function(field) {
                  return field.isValidate
                }),
                isHeavierPrevRow: (value: number, field: IPenaltyList) => {
                  if (!field.isAddButton) {
                    const currentIdx = this.form.penalty.list.findIndex(
                      penalty => penalty.fieldId === field.fieldId
                    )

                    if (currentIdx > 0) {
                      if (
                        this.form.penalty.list[currentIdx - 1]
                          .timePickupMaximum &&
                        value
                      ) {
                        return (
                          <number>(
                            this.form.penalty.list[currentIdx - 1]
                              .timePickupMaximum
                          ) < value
                        )
                      }
                    }
                  }
                  return true
                },
              },
              penaltyAmount: {
                required: requiredIf(function(field) {
                  return field.isValidate
                }),
              },
            },
          },
        },
      },
    }
  }

  get isEditPage(): boolean {
    return this.$route.name === 'RewardAndPunishmentEdit'
  }

  get isDetailPage(): boolean {
    return this.$route.name === 'RewardAndPunishmentDetail'
  }

  get currentBonusKey(): 'weekday' | 'weekend' {
    if (this.bonusCurrentTab === this.bonusItemTabs[0].value) {
      return 'weekday'
    } else {
      return 'weekend'
    }
  }

  get bonusForm(): IBonusForm {
    if (this.bonusCurrentTab === this.bonusItemTabs[0].value) {
      return this.form.bonus.weekday
    } else {
      return this.form.bonus.weekend
    }
  }

  set bonusForm(val: IBonusForm) {
    if (this.bonusCurrentTab === this.bonusItemTabs[0].value) {
      this.form.bonus.weekday = val
    } else {
      this.form.bonus.weekend = val
    }
  }

  get pageTitle(): string {
    let prefix = 'Tambah'

    if (this.isEditPage) {
      prefix = 'Edit'
    }

    if (this.isDetailPage) {
      prefix = 'Detail'
    }

    return `${prefix} Bonus & Penalti`
  }

  public fetchProgramDetail(): void {
    const programId = <string>this.$route.params.programId
    const levelId = <string>this.$route.params.levelId

    const payload = {
      programId: parseInt(programId),
      levelId: parseInt(levelId),
    }

    if (levelId) {
      this.controller.getProgramLevel(payload)
    } else {
      this.controller.getProgramDetail({
        programId: payload.programId,
        params: {},
      })
    }
  }

  public onInputAvgCourierRateOperator(
    value: IOptions,
    type: 'avgCourierRate' | 'ignoreAndRejectCourierRate' | 'successPickupRate'
  ): void {
    if (!value) {
      ;(<IForm>this.form)[type].operator = this.operatorOptions[0]
    }
  }

  public onTabClick(tab: string): void {
    this.currentTab = tab
  }

  public onBonusTabClick(tab: string): void {
    this.bonusCurrentTab = tab
  }

  public onAddBonusFee(index: number): void {
    this.bonusForm.data[index].bonusFee.push({
      isValidate: true,
      bonusFeeId: null,
      fieldId: new Date().getTime() + Math.random(),
      fee: null,
      isUnlimited: false,
      quota: null,
    })
  }

  public onAddBonus(): void {
    this.bonusForm.data.splice(this.bonusForm.data.length - 1, 0, {
      isValidate: true,
      bonusId: null,
      fieldId: new Date().getTime() + Math.random(),
      isAddButton: false,
      isRandomize: false,
      timePickupMaximum: null,
      timePickupMinimum:
        <number>(
          this.bonusForm.data[this.bonusForm.data.length - 2].timePickupMaximum
        ) > 0
          ? <number>(
              this.bonusForm.data[this.bonusForm.data.length - 2]
                .timePickupMaximum
            ) + 1
          : null,
      isTimePickupMinimumDisabled:
        <number>(
          this.bonusForm.data[this.bonusForm.data.length - 2].timePickupMaximum
        ) > 0
          ? true
          : false,
      bonusFee: [
        {
          isValidate: true,
          bonusFeeId: null,
          fieldId: new Date().getTime() + Math.random(),
          fee: null,
          isUnlimited: false,
          quota: null,
        },
      ],
    })
  }

  public onAddPenalty(): void {
    this.form.penalty.list.splice(this.form.penalty.list.length - 1, 0, {
      isValidate: true,
      penaltyId: null,
      fieldId: new Date().getTime() + Math.random(),
      isAddButton: false,
      timePickupMaximum: null,
      timePickupMinimum:
        <number>(
          this.form.penalty.list[this.form.penalty.list.length - 2]
            .timePickupMaximum
        ) > 0
          ? <number>(
              this.form.penalty.list[this.form.penalty.list.length - 2]
                .timePickupMaximum
            ) + 1
          : null,
      isTimePickupMinimumDisabled:
        <number>(
          this.form.penalty.list[this.form.penalty.list.length - 2]
            .timePickupMaximum
        ) > 0
          ? true
          : false,
      penaltyAmount: null,
    })
  }

  public onDeleteBonusFee(index: number, feeIndex: number): void {
    this.bonusForm.data[index].bonusFee.splice(feeIndex, 1)
  }

  public onDeleteBonus(index: number): void {
    this.bonusForm.data.splice(index, 1)
    this.bonusForm.data = this.bonusForm.data.map((bonus, i) => {
      if (i === 0) {
        return {
          ...bonus,
          isTimePickupMinimumDisabled: false,
        }
      } else {
        if (
          this.bonusForm.data[i - 1].timePickupMaximum &&
          this.bonusForm.data[i - 1].timePickupMaximum !==
            <number>bonus.timePickupMinimum + 1
        ) {
          return {
            ...bonus,
            timePickupMinimum:
              <number>this.bonusForm.data[i - 1].timePickupMaximum + 1,
          }
        } else {
          return bonus
        }
      }
    })
  }

  public onDeletePenalty(index: number): void {
    this.form.penalty.list.splice(index, 1)
    this.form.penalty.list = this.form.penalty.list.map((penalty, i) => {
      if (i === 0) {
        return {
          ...penalty,
          isTimePickupMinimumDisabled: false,
        }
      } else {
        if (
          this.form.penalty.list[i - 1].timePickupMaximum &&
          this.form.penalty.list[i - 1].timePickupMaximum !==
            <number>penalty.timePickupMinimum + 1
        ) {
          return {
            ...penalty,
            timePickupMinimum:
              <number>this.form.penalty.list[i - 1].timePickupMaximum + 1,
          }
        } else {
          return penalty
        }
      }
    })
  }

  public onToggleRandomize(index: number): void {
    this.bonusForm.data[index].isRandomize = !this.bonusForm.data[index]
      .isRandomize
    if (!this.bonusForm.data[index].isRandomize) {
      this.bonusForm.data[index].bonusFee = this.bonusForm.data[
        index
      ].bonusFee.filter((_fee, i) => i === 0)
    }
  }

  public onSubmit(): void {
    this.isConfirmModalVisible = false

    const programId = <string>this.$route.params.programId
    const levelId = <string>this.$route.params.levelId
    const payload = new CreateProgramLevelBonusRewardAndPunishmentRequest(
      this.form.level.name,
      new CourierRating(
        <number>this.form.avgCourierRate.rate,
        undefined,
        <string>this.form.avgCourierRate.operator?.value
      ),
      new RejectIgnoreSuccessPickup(
        <number>this.form.ignoreAndRejectCourierRate.percentage,
        undefined,
        <string>this.form.ignoreAndRejectCourierRate.operator?.value
      ),
      new RejectIgnoreSuccessPickup(
        <number>this.form.successPickupRate.percentage,
        undefined,
        <string>this.form.successPickupRate.operator?.value
      ),
      new ProgramLevelBonus(
        new ProgramLevelBonusDay(
          this.form.bonus.weekday.isPickup,
          this.form.bonus.weekday.isDelivery,
          <number>this.form.bonus.weekday.withdrawalFee,
          this.form.bonus.weekday.data
            .filter(bonus => bonus.isValidate)
            .map(bonus => {
              return new ProgramLevelBonusData(
                <number>bonus.bonusId || undefined,
                bonus.isRandomize,
                <number>bonus.timePickupMinimum,
                <number>bonus.timePickupMaximum,
                bonus.bonusFee.map(bonusFee => {
                  return new ProgramLevelBonusFee(
                    <number>bonusFee.bonusFeeId || undefined,
                    <number>bonusFee.fee,
                    <number>bonusFee.quota,
                    bonusFee.isUnlimited
                  )
                })
              )
            })
        ),
        new ProgramLevelBonusDay(
          this.form.bonus.weekend.isPickup,
          this.form.bonus.weekend.isDelivery,
          <number>this.form.bonus.weekend.withdrawalFee,
          this.form.bonus.weekend.data
            .filter(bonus => bonus.isValidate)
            .map(bonus => {
              return new ProgramLevelBonusData(
                <number>bonus.bonusId || undefined,
                bonus.isRandomize,
                <number>bonus.timePickupMinimum,
                <number>bonus.timePickupMaximum,
                bonus.bonusFee.map(bonusFee => {
                  return new ProgramLevelBonusFee(
                    <number>bonusFee.bonusFeeId || undefined,
                    <number>bonusFee.fee,
                    <number>bonusFee.quota,
                    bonusFee.isUnlimited
                  )
                })
              )
            })
        )
      ),
      new ProgramLevelPenalty(
        this.form.penalty.isFreeze,
        this.form.penalty.list
          .filter(penalty => penalty.isValidate)
          .map(penalty => {
            return new ProgramLevelPenaltyData(
              <number>penalty.penaltyId,
              <number>penalty.timePickupMinimum,
              <number>penalty.timePickupMaximum,
              <number>penalty.penaltyAmount
            )
          })
      )
    )

    if (!this.isEditPage) {
      this.controller.CreateProgramLevel({
        programId: parseInt(programId),
        payload,
      })
    } else {
      this.controller.UpdateProgramLevel({
        levelId: parseInt(levelId),
        programId: parseInt(programId),
        payload,
      })
    }
  }

  @Watch('controller.programDetailData')
  onProgramDetailDataChanged(data: ProgramDetail): void {
    this.detailData = {
      ...data,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      status: data.status,
      periodeStart: data.periodeStart,
      periodeEnd: data.periodeEnd,
    }
  }

  @Watch('controller.programLevelDetailData')
  onProgramLevelDetailDataChanged(data: ProgramLevelDetail): void {
    this.detailData = {
      ...data,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      status: data.status,
      createdBy: data.createdBy,
      evaluationSchedule: data.evaluationSchedule,
    }

    this.form = {
      ...this.form,
      level: {
        name: <string>data.levelName,
        operator: undefined,
      },
      avgCourierRate: {
        rate: <number>data.courierRating?.rate,
        operator: this.operatorOptions.find(
          operator =>
            operator.value ===
            data.courierRating?.operatorText?.toLocaleLowerCase()
        ),
      },
      ignoreAndRejectCourierRate: {
        percentage: <number>data.rejectIgnore?.percentage,
        operator: this.operatorOptions.find(
          operator =>
            operator.value ===
            data.rejectIgnore?.operatorText?.toLocaleLowerCase()
        ),
      },
      successPickupRate: {
        percentage: <number>data.successPickup?.percentage,
        operator: this.operatorOptions.find(
          operator =>
            operator.value ===
            data.successPickup?.operatorText?.toLocaleLowerCase()
        ),
      },
      bonus: {
        weekday: {
          isPickup: <boolean>data.bonus?.weekday?.isPickup,
          isDelivery: <boolean>data.bonus?.weekday?.isDelivery,
          withdrawalFee: <number>data.bonus?.weekday?.withdrawalFee,
          data: <IBonusFormData[]>data.bonus?.weekday?.data?.map(
            (bonus, index) => ({
              bonusId: bonus.bonusId,
              bonusFee: <IBonusFee[]>bonus.bonusFee?.map(bonusFee => ({
                ...bonusFee,
                isValidate: true,
                fieldId: new Date().getTime() + Math.random(),
              })),
              timePickupMaximum: bonus.timePickupMaximum,
              timePickupMinimum: bonus.timePickupMinimum,
              isValidate: true,
              isAddButton: false,
              isTimePickupMinimumDisabled: index !== 0,
              isRandomize: bonus.isRandomize,
              fieldId: new Date().getTime() + Math.random(),
            })
          ),
        },
        weekend: {
          isPickup: <boolean>data.bonus?.weekend?.isPickup,
          isDelivery: <boolean>data.bonus?.weekend?.isDelivery,
          withdrawalFee: <number>data.bonus?.weekend?.withdrawalFee,
          data: <IBonusFormData[]>data.bonus?.weekend?.data?.map(
            (bonus, index) => ({
              bonusId: bonus.bonusId,
              bonusFee: <IBonusFee[]>bonus.bonusFee?.map(bonusFee => ({
                ...bonusFee,
                isValidate: true,
                fieldId: new Date().getTime() + Math.random(),
              })),
              timePickupMaximum: bonus.timePickupMaximum,
              timePickupMinimum: bonus.timePickupMinimum,
              isValidate: true,
              isAddButton: false,
              isTimePickupMinimumDisabled: index !== 0,
              isRandomize: bonus.isRandomize,
              fieldId: new Date().getTime() + Math.random(),
            })
          ),
        },
      },
      penalty: {
        isFreeze: <boolean>data.penalty?.isFreeze,
        list: <IPenaltyList[]>data.penalty?.list?.map((penalty, index) => ({
          fieldId: new Date().getTime() + Math.random(),
          isAddButton: false,
          isTimePickupMinimumDisabled: index !== 0,
          isValidate: true,
          penaltyAmount: <number>penalty.penaltyAmount,
          penaltyId: penalty.penaltyId,
          timePickupMaximum: <number>penalty.timePickupMaximum,
          timePickupMinimum: <number>penalty.timePickupMinimum,
        })),
      },
    }

    const weekdayBonusData = this.form.bonus.weekday.data
    const weekendBonusData = this.form.bonus.weekend.data
    const addButtonObj = {
      isValidate: false,
      isAddButton: true,
      bonusId: null,
      fieldId: new Date().getTime() + Math.random(),
      isRandomize: false,
      timePickupMaximum: null,
      timePickupMinimum: null,
      isTimePickupMinimumDisabled: false,
      bonusFee: [
        {
          isValidate: false,
          bonusFeeId: 1,
          fieldId: new Date().getTime() + Math.random(),
          fee: null,
          isUnlimited: false,
          quota: null,
        },
      ],
    }

    const emptyFieldObj = {
      isValidate: true,
      isAddButton: false,
      bonusId: null,
      isRandomize: false,
      fieldId: new Date().getTime() + Math.random(),
      timePickupMaximum: null,
      timePickupMinimum: null,
      isTimePickupMinimumDisabled: false,
      bonusFee: [
        {
          isValidate: true,
          bonusFeeId: 1,
          fieldId: new Date().getTime() + Math.random(),
          fee: null,
          isUnlimited: false,
          quota: null,
        },
      ],
    }

    if (!weekdayBonusData.length) {
      weekdayBonusData.push(emptyFieldObj)
    }

    if (!weekendBonusData.length) {
      weekendBonusData.push(emptyFieldObj)
    }

    if (!this.isDetailPage) {
      weekdayBonusData.push(addButtonObj)
      weekendBonusData.push(addButtonObj)
    }

    this.form.bonus = {
      weekday: {
        ...this.form.bonus.weekday,
        data: weekdayBonusData,
      },
      weekend: {
        ...this.form.bonus.weekend,
        data: weekendBonusData,
      },
    }

    const penaltyData = this.form.penalty.list

    if (!penaltyData.length) {
      penaltyData.push({
        isAddButton: false,
        isValidate: true,
        timePickupMaximum: null,
        timePickupMinimum: null,
        penaltyId: null,
        fieldId: new Date().getTime() + Math.random(),
        penaltyAmount: null,
        isTimePickupMinimumDisabled: false,
      })
    }

    if (!this.isDetailPage) {
      penaltyData.push({
        isAddButton: true,
        isValidate: false,
        penaltyAmount: null,
        penaltyId: null,
        fieldId: new Date().getTime() + Math.random(),
        timePickupMaximum: null,
        timePickupMinimum: null,
        isTimePickupMinimumDisabled: false,
      })
    }

    this.form.penalty = {
      ...this.form.penalty,
      list: penaltyData,
    }

    this.$v.form.$reset()
  }

  @Watch('controller.isSubmitProgramLevelSuccess')
  onIsSubmitProgramLevelSuccess(data: boolean): void {
    if (data) {
      this.controller.setIsSubmitProgramLevelSuccess(false)
      this.isSuccessModalVisible = true
    }
  }

  @Watch('$route.path')
  onPathChanged(): void {
    this.fetchProgramDetail()
  }
}
