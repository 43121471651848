var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h1',{staticClass:"text-xl font-semibold text-gray-4"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('DetailSummary',{attrs:{"hideHistoryLog":!_vm.isEditPage && !_vm.isDetailPage,"data":_vm.detailData}}),_c('div',{staticClass:"border border-gray-2 rounded-lg p-6 mt-8 mb-6"},[_c('h2',{staticClass:"text-xl text-bgMatterhorn font-semibold mb-4"},[_vm._v(" Kriteria Level Kurir ")]),_c('div',{staticClass:"grid grid-cols-4 gap-6"},[_vm._m(0),_c('div',{staticClass:"col-span-2 flex flex-col gap-4"},[_c('LevelTextInput',{attrs:{"placeholder":"Masukkan nama level","errorMessage":_vm.$v.form.level.name.$dirty && !_vm.$v.form.level.name.required
              ? 'harus diisi'
              : _vm.$v.form.level.name.$dirty &&
                (!_vm.$v.form.level.name.minLength ||
                  !_vm.$v.form.level.name.maxLength)
              ? 'Nama level harus 3 sampai 100 karakter'
              : '',"disabled":_vm.isDetailPage},model:{value:(_vm.$v.form.level.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.level.name, "$model", $$v)},expression:"$v.form.level.name.$model"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('DropdownSelect',{attrs:{"options":_vm.operatorOptions,"disabled":_vm.isDetailPage},on:{"input":function (val) { return _vm.onInputAvgCourierRateOperator(val, 'avgCourierRate'); }},model:{value:(_vm.$v.form.avgCourierRate.operator.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.avgCourierRate.operator, "$model", $$v)},expression:"$v.form.avgCourierRate.operator.$model"}}),_c('LevelTextInput',{attrs:{"placeholder":"Masukkan rating. Contoh: 4.3","allowDot":"","type":"number","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.avgCourierRate.rate.$dirty &&
              !_vm.$v.form.avgCourierRate.rate.required
                ? 'harus diisi'
                : _vm.$v.form.avgCourierRate.rate.$dirty &&
                  !_vm.$v.form.avgCourierRate.rate.maxValue
                ? 'Rating kurir tidak boleh lebih dari 5'
                : _vm.$v.form.avgCourierRate.rate.$dirty &&
                  !_vm.$v.form.avgCourierRate.rate.minValue
                ? 'Rating kurir tidak boleh kurang dari 1'
                : ''},model:{value:(_vm.$v.form.avgCourierRate.rate.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.avgCourierRate.rate, "$model", $$v)},expression:"$v.form.avgCourierRate.rate.$model"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('DropdownSelect',{attrs:{"options":_vm.operatorOptions,"disabled":_vm.isDetailPage},on:{"input":function (val) { return _vm.onInputAvgCourierRateOperator(
                  val,
                  'ignoreAndRejectCourierRate'
                ); }},model:{value:(_vm.$v.form.ignoreAndRejectCourierRate.operator.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.ignoreAndRejectCourierRate.operator, "$model", $$v)},expression:"$v.form.ignoreAndRejectCourierRate.operator.$model"}}),_c('LevelTextInput',{attrs:{"placeholder":"Masukkan persentase","fieldSuffix":"%","type":"number","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.ignoreAndRejectCourierRate.percentage.$dirty &&
              !_vm.$v.form.ignoreAndRejectCourierRate.percentage.required
                ? 'harus diisi'
                : _vm.$v.form.ignoreAndRejectCourierRate.percentage.$dirty &&
                  !_vm.$v.form.ignoreAndRejectCourierRate.percentage.minValue
                ? 'harus lebih dari 0'
                : _vm.$v.form.ignoreAndRejectCourierRate.percentage.$dirty &&
                  !_vm.$v.form.ignoreAndRejectCourierRate.percentage.maxValue
                ? 'tidak boleh lebih dari 100'
                : ''},model:{value:(_vm.$v.form.ignoreAndRejectCourierRate.percentage.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.ignoreAndRejectCourierRate.percentage, "$model", $$v)},expression:"$v.form.ignoreAndRejectCourierRate.percentage.$model"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('DropdownSelect',{attrs:{"options":_vm.operatorOptions,"disabled":_vm.isDetailPage},on:{"input":function (val) { return _vm.onInputAvgCourierRateOperator(val, 'successPickupRate'); }},model:{value:(_vm.$v.form.successPickupRate.operator.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.successPickupRate.operator, "$model", $$v)},expression:"$v.form.successPickupRate.operator.$model"}}),_c('LevelTextInput',{attrs:{"type":"number","placeholder":"Masukkan persentase","fieldSuffix":"%","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.successPickupRate.percentage.$dirty &&
              !_vm.$v.form.successPickupRate.percentage.required
                ? 'harus diisi'
                : _vm.$v.form.successPickupRate.percentage.$dirty &&
                  !_vm.$v.form.successPickupRate.percentage.minValue
                ? 'harus lebih dari 0'
                : _vm.$v.form.successPickupRate.percentage.$dirty &&
                  !_vm.$v.form.successPickupRate.percentage.maxValue
                ? 'tidak boleh lebih dari 100'
                : ''},model:{value:(_vm.$v.form.successPickupRate.percentage.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.successPickupRate.percentage, "$model", $$v)},expression:"$v.form.successPickupRate.percentage.$model"}})],1)],1)])]),_c('div',{staticClass:"mb-6"},[_c('Tabs',{attrs:{"itemTabs":_vm.itemTabs,"currentTab":_vm.currentTab},on:{"tabsClick":_vm.onTabClick}}),(_vm.currentTab === _vm.itemTabs[0].value)?_c('div',{staticClass:"border border-gray-2 rounded-lg p-6 mt-6"},[_c('h2',{staticClass:"text-xl text-bgMatterhorn font-semibold mb-4"},[_vm._v(" Bonus Penghasilan Kurir ")]),_c('Tabs',{attrs:{"itemTabs":_vm.bonusItemTabs,"currentTab":_vm.bonusCurrentTab},on:{"tabsClick":_vm.onBonusTabClick}}),_c('div',{staticClass:"mt-6 flex flex-col gap-6"},[_c('div',{staticClass:"flex flex-row gap-2"},[_c('CheckBox',{attrs:{"isChecked":""}}),_c('span',{staticClass:"text-bgMatterhorn font-semibold"},[_vm._v("Pick Up")])],1),_c('div',{staticClass:"flex flex-col gap-2 w-80"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold text-sm"},[_vm._v("Biaya Admin saat Penarikan")]),_c('LevelTextInput',{attrs:{"type":"currency","placeholder":"Masukkan biaya admin","withDefaultVal":"","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.bonus[_vm.currentBonusKey].withdrawalFee.$dirty &&
              !_vm.$v.form.bonus[_vm.currentBonusKey].withdrawalFee.required
                ? 'harus diisi'
                : ''},model:{value:(_vm.$v.form.bonus[_vm.currentBonusKey].withdrawalFee.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.bonus[_vm.currentBonusKey].withdrawalFee, "$model", $$v)},expression:"$v.form.bonus[currentBonusKey].withdrawalFee.$model"}})],1),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold text-sm"},[_vm._v("Tingkatan Bonus")]),_c('div',{staticClass:"overflow-x-auto  "},[_c('div',{staticClass:"table w-full min-h-64"},[_c('div',{staticClass:"flex flex-col"},[_vm._m(1),_c('div',{staticClass:"border border-gray-2 rounded-lg rounded-t-none"},_vm._l((_vm.form.bonus[_vm.currentBonusKey].data),function(bonus,index){return _c('div',{key:index},[(!bonus.isAddButton)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex justify-end pt-4 px-6"},[(
                            _vm.isDetailPage ? false : _vm.bonusForm.data.length > 2
                          )?_c('span',{staticClass:"text-red-7 text-sm font-semibold cursor-pointer",on:{"click":function () {
                              if (_vm.isDetailPage) { return }
                              _vm.onDeleteBonus(index)
                            }}},[_vm._v("Hapus Tingkatan")]):_vm._e()]),_c('div',{staticClass:"flex flex-row gap-6 mb-2"},[_c('div',{staticClass:"min-w-12"}),_c('div',{staticClass:"min-w-40"}),_c('div',{staticClass:"min-w-40"}),_c('div',{staticClass:"min-w-50"},[_c('div',{staticClass:"flex flex-row gap-2"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Pembagian Acak")]),_c('div',{staticClass:"toggle",class:[{ disabled: _vm.isDetailPage }],on:{"click":function () {
                                  if (_vm.isDetailPage) { return }
                                  _vm.onToggleRandomize(index)
                                }}},[_c('div',{staticClass:"text-sm font-semibold"}),_c('div',{staticClass:"switch",class:[{ active: bonus.isRandomize }]},[_c('div')])])])])]),_c('div',{staticClass:"flex flex-row gap-6 pb-12 border-b border-black-1"},[_c('div',{staticClass:"min-w-12 px-4"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"min-w-40"},[_c('LevelTextInput',{attrs:{"placeholder":"Contoh: 10","disabled":bonus.isTimePickupMinimumDisabled ||
                                _vm.isDetailPage,"type":"number","errorMessage":_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMinimum.$dirty
                                ? !_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                    index
                                  ].timePickupMinimum.required
                                  ? 'harus diisi'
                                  : !_vm.$v.form.bonus[_vm.currentBonusKey].data
                                      .$each[index].timePickupMinimum
                                      .isHeavierPrevRow
                                  ? 'Waktu bertabrakan dengan rentang waktu lainnya'
                                  : ''
                                : ''},model:{value:(
                              _vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMinimum.$model
                            ),callback:function ($$v) {_vm.$set(_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMinimum, "$model", $$v)},expression:"\n                              $v.form.bonus[currentBonusKey].data.$each[index]\n                                .timePickupMinimum.$model\n                            "}})],1),_c('div',{staticClass:"min-w-40"},[_c('LevelTextInput',{attrs:{"placeholder":"Contoh: 60","type":"number","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMaximum.$dirty &&
                              !_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                index
                              ].timePickupMaximum.required
                                ? 'harus diisi'
                                : !_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                    index
                                  ].timePickupMaximum.lessThanMinimum
                                ? 'Waktu bertabrakan dengan rentang waktu lainnya'
                                : ''},model:{value:(
                              _vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMaximum.$model
                            ),callback:function ($$v) {_vm.$set(_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[index]
                                .timePickupMaximum, "$model", $$v)},expression:"\n                              $v.form.bonus[currentBonusKey].data.$each[index]\n                                .timePickupMaximum.$model\n                            "}})],1),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((bonus.bonusFee),function(fee,feeIndex){return _c('div',{key:feeIndex,staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"flex flex-row gap-6"},[_c('div',{staticClass:"min-w-50"},[_c('LevelTextInput',{attrs:{"type":"currency","placeholder":"Nominal Bonus","withDefaultVal":"","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                      index
                                    ].bonusFee.$each[feeIndex].fee.$dirty &&
                                    !_vm.$v.form.bonus[_vm.currentBonusKey].data
                                      .$each[index].bonusFee.$each[feeIndex]
                                      .fee.required
                                      ? 'harus diisi'
                                      : ''},model:{value:(
                                    _vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                      index
                                    ].bonusFee.$each[feeIndex].fee.$model
                                  ),callback:function ($$v) {_vm.$set(_vm.$v.form.bonus[_vm.currentBonusKey].data.$each[
                                      index
                                    ].bonusFee.$each[feeIndex].fee, "$model", $$v)},expression:"\n                                    $v.form.bonus[currentBonusKey].data.$each[\n                                      index\n                                    ].bonusFee.$each[feeIndex].fee.$model\n                                  "}})],1),_c('div',{staticClass:"flex flex-row gap-6 items-center"},[_c('div',{staticClass:"w-28"},[_c('LevelTextInput',{attrs:{"type":"number","fieldSuffix":"X","placeholder":"400","disabled":fee.isUnlimited || _vm.isDetailPage,"errorMessage":_vm.$v.form.bonus[_vm.currentBonusKey].data
                                        .$each[index].bonusFee.$each[feeIndex]
                                        .quota.$dirty &&
                                      !_vm.$v.form.bonus[_vm.currentBonusKey].data
                                        .$each[index].bonusFee.$each[feeIndex]
                                        .quota.required
                                        ? 'harus diisi'
                                        : ''},model:{value:(
                                      _vm.$v.form.bonus[_vm.currentBonusKey].data
                                        .$each[index].bonusFee.$each[feeIndex]
                                        .quota.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.form.bonus[_vm.currentBonusKey].data
                                        .$each[index].bonusFee.$each[feeIndex]
                                        .quota, "$model", $$v)},expression:"\n                                      $v.form.bonus[currentBonusKey].data\n                                        .$each[index].bonusFee.$each[feeIndex]\n                                        .quota.$model\n                                    "}})],1),_c('div',{staticClass:"flex flex-row gap-2 items-center"},[_c('CheckBox',{attrs:{"isChecked":fee.isUnlimited},on:{"click":function () {
                                        if (_vm.isDetailPage) { return }
                                        fee.isUnlimited = !fee.isUnlimited
                                      }}}),_c('span',{staticClass:"text-sm font-semibold text-bgMatterhorn w-28"},[_vm._v("Tidak Terbatas")])],1),(_vm.isDetailPage ? false : feeIndex !== 0)?_c('div',{staticClass:"text-sm text-bgPrimary font-semibold cursor-pointer",on:{"click":function () {
                                      if (_vm.isDetailPage) { return }
                                      _vm.onDeleteBonusFee(index, feeIndex)
                                    }}},[_vm._v(" Hapus ")]):_vm._e()])]),(
                                _vm.isDetailPage
                                  ? false
                                  : bonus.isRandomize &&
                                    feeIndex === bonus.bonusFee.length - 1
                              )?_c('div',{staticClass:"flex flex-row gap-1 text-sm text-bgPrimary font-semibold cursor-pointer",on:{"click":function () {
                                  if (_vm.isDetailPage) { return }
                                  _vm.onAddBonusFee(index)
                                }}},[_c('AddIcon'),_vm._v(" Tambah ")],1):_vm._e()])}),0)])]):_vm._e(),(bonus.isAddButton)?_c('div',{staticClass:"py-5 flex flex-row gap-6"},[_c('div',{staticClass:"min-w-12 px-4"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"min-w-40"},[(!_vm.isDetailPage)?_c('Button',{staticClass:"flex flex-row gap-2 text-sm text-bgPrimary font-semibold",attrs:{"buttonStyle":"outline"},on:{"click":_vm.onAddBonus}},[_c('AddIcon'),_vm._v(" Tingkatan ")],1):_vm._e()],1)]):_vm._e()])}),0)])])])]),_c('div',{staticClass:"flex flex-row gap-2"},[_c('CheckBox',{attrs:{"disabled":""}}),_c('span',{staticClass:"text-bgMatterhorn font-semibold"},[_vm._v("Delivery")])],1)])],1):_vm._e(),(_vm.currentTab === _vm.itemTabs[1].value)?_c('div',{staticClass:"border border-gray-2 rounded-lg p-6 mt-6"},[_c('h2',{staticClass:"text-xl text-bgMatterhorn font-semibold mb-4"},[_vm._v(" Penalti Kurir ")]),_c('div',{staticClass:"flex flex-col gap-2 my-6"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Akun Dibekukan")]),_c('div',{staticClass:"flex flex-row gap-2 items-center"},[_c('div',{staticClass:"toggle",on:{"click":function () {
                if (_vm.isDetailPage) { return }
                _vm.form.penalty.isFreeze = !_vm.form.penalty.isFreeze
              }}},[_c('div',{staticClass:"text-sm font-semibold"}),_c('div',{staticClass:"switch",class:[{ active: _vm.form.penalty.isFreeze }]},[_c('div')])]),_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v(_vm._s(!_vm.form.penalty.isFreeze ? 'Nonaktif' : 'Aktif'))])])]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold text-sm"},[_vm._v("Tingkatan Penalti")]),_c('div',{staticClass:"overflow-x-auto  "},[_c('div',{staticClass:"table w-full min-h-64"},[_c('div',{staticClass:"flex flex-col"},[_vm._m(2),_c('div',{staticClass:"border border-gray-2 rounded-lg rounded-t-none"},_vm._l((_vm.form.penalty.list),function(penalty,index){return _c('div',{key:index},[_c('div',{staticClass:"flex flex-row gap-6 border-b py-5 border-black-1"},[(!penalty.isAddButton)?_c('div',{staticClass:"flex flex-row gap-6 "},[_c('div',{staticClass:"min-w-12 px-4"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"min-w-40"},[_c('LevelTextInput',{attrs:{"placeholder":"Contoh: 10","disabled":penalty.isTimePickupMinimumDisabled ||
                              _vm.isDetailPage,"type":"number","errorMessage":_vm.$v.form.penalty.list.$each[index]
                              .timePickupMinimum.$dirty
                              ? !_vm.$v.form.penalty.list.$each[index]
                                  .timePickupMinimum.required
                                ? 'harus diisi'
                                : !_vm.$v.form.penalty.list.$each[index]
                                    .timePickupMinimum.isHeavierPrevRow
                                ? 'Waktu bertabrakan dengan rentang waktu lainnya'
                                : ''
                              : ''},model:{value:(
                            _vm.$v.form.penalty.list.$each[index]
                              .timePickupMinimum.$model
                          ),callback:function ($$v) {_vm.$set(_vm.$v.form.penalty.list.$each[index]
                              .timePickupMinimum, "$model", $$v)},expression:"\n                            $v.form.penalty.list.$each[index]\n                              .timePickupMinimum.$model\n                          "}})],1),_c('div',{staticClass:"min-w-40"},[_c('LevelTextInput',{attrs:{"placeholder":"Contoh: 60","type":"number","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.penalty.list.$each[index]
                              .timePickupMaximum.$dirty &&
                            !_vm.$v.form.penalty.list.$each[index]
                              .timePickupMaximum.required
                              ? 'harus diisi'
                              : _vm.$v.form.penalty.list.$each[index]
                                  .timePickupMaximum.$dirty &&
                                !_vm.$v.form.penalty.list.$each[index]
                                  .timePickupMaximum.lessThanMinimum
                              ? 'Waktu bertabrakan dengan rentang waktu lainnya'
                              : ''},model:{value:(
                            _vm.$v.form.penalty.list.$each[index]
                              .timePickupMaximum.$model
                          ),callback:function ($$v) {_vm.$set(_vm.$v.form.penalty.list.$each[index]
                              .timePickupMaximum, "$model", $$v)},expression:"\n                            $v.form.penalty.list.$each[index]\n                              .timePickupMaximum.$model\n                          "}})],1),_c('div',{staticClass:"min-w-50"},[_c('LevelTextInput',{attrs:{"placeholder":"Nominal Penalti","type":"currency","prefixCurrency":"-Rp","withDefaultVal":"","disabled":_vm.isDetailPage,"errorMessage":_vm.$v.form.penalty.list.$each[index].penaltyAmount
                              .$dirty &&
                            !_vm.$v.form.penalty.list.$each[index].penaltyAmount
                              .required
                              ? 'harus diisi'
                              : ''},model:{value:(
                            _vm.$v.form.penalty.list.$each[index].penaltyAmount
                              .$model
                          ),callback:function ($$v) {_vm.$set(_vm.$v.form.penalty.list.$each[index].penaltyAmount
                              , "$model", $$v)},expression:"\n                            $v.form.penalty.list.$each[index].penaltyAmount\n                              .$model\n                          "}})],1),_c('div',{staticClass:"flex items-center justify-center"},[(
                            _vm.isDetailPage
                              ? false
                              : _vm.form.penalty.list.length > 2
                          )?_c('span',{staticClass:"text-red-7 text-sm font-semibold cursor-pointer",on:{"click":function () {
                              if (_vm.isDetailPage) { return }
                              _vm.onDeletePenalty(index)
                            }}},[_vm._v("Hapus Tingkatan")]):_vm._e()])]):_vm._e(),(penalty.isAddButton)?_c('div',{staticClass:"flex flex-row gap-6"},[_c('div',{staticClass:"min-w-12 px-4"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"min-w-40"},[(!_vm.isDetailPage)?_c('Button',{staticClass:"flex flex-row gap-2 text-sm text-bgPrimary font-semibold",attrs:{"buttonStyle":"outline"},on:{"click":_vm.onAddPenalty}},[_c('AddIcon'),_vm._v(" Tingkatan ")],1):_vm._e()],1)]):_vm._e()])])}),0)])])])])]):_vm._e()],1),_c('div',{staticClass:"w-full flex flex-row justify-end items-center gap-2.5"},[_c('Button',{staticClass:"text-sm",attrs:{"buttonStyle":"outline"},on:{"click":function () {
          _vm.$router.go(-1)
        }}},[_vm._v(_vm._s(_vm.isDetailPage ? 'Kembali' : 'Batalkan'))]),[(_vm.isDetailPage)?_c('Button',{staticClass:"text-sm",attrs:{"routeTo":{
          name: 'RewardAndPunishmentEdit',
          params: {
            programId: _vm.$route.params.programId,
            levelId: _vm.$route.params.levelId,
          },
        }}},[_vm._v(" Edit ")]):_c('Button',{staticClass:"text-sm",attrs:{"disabled":_vm.$v.form.$invalid},on:{"click":function () {
            _vm.isConfirmModalVisible = true
          }}},[_vm._v("Simpan")])]],2),_c('ModalConfirm',{attrs:{"visible":_vm.isConfirmModalVisible,"title":"Simpan Bonus & Penalti untuk Program ini?","description":"Pastikan detail bonus & penalti yang Anda isi sudah sesuai","actionBtnLabel":"Ya, Simpan"},on:{"cancel":function () {
        _vm.isConfirmModalVisible = false
      },"action":_vm.onSubmit}}),_c('ModalSuccess',{attrs:{"visible":_vm.isSuccessModalVisible,"title":"Bonus & Penalti Berhasil Disimpan"},on:{"action":function () {
        _vm.$router.push({
          name: 'RewardAndPunishmentProgramDetail',
          params: {
            programId: _vm.$route.params.programId,
          },
        })
      }}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-1 flex flex-col gap-4 text-secondaryText"},[_c('span',{staticClass:"py-2"},[_vm._v("Nama Level")]),_c('span',{staticClass:"py-2"},[_vm._v("Rating Kurir")]),_c('span',{staticClass:"py-2"},[_vm._v("Ditolak & Diabaikan")]),_c('span',{staticClass:"py-2"},[_vm._v("Sukses Pick Up")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row  bg-gray-3 gap-6 border-t border-l border-r border-gray-2 rounded-lg rounded-b-none"},[_c('div',{staticClass:"th min-w-12 justify-center"},[_vm._v(" No ")]),_c('div',{staticClass:"th min-w-40"},[_vm._v(" Waktu Pick Up Min (Menit) ")]),_c('div',{staticClass:"th min-w-40"},[_vm._v(" Waktu Pick Up Maks (Menit) ")]),_c('div',{staticClass:"th min-w-50"},[_vm._v(" Bonus ")]),_c('div',{staticClass:"th w-auto"},[_vm._v(" Batasan ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row  bg-gray-3 gap-6 border-t border-l border-r border-gray-2 rounded-lg rounded-b-none"},[_c('div',{staticClass:"th min-w-12 justify-center"},[_vm._v(" No ")]),_c('div',{staticClass:"th min-w-40"},[_vm._v(" Waktu Pick Up Min (Menit) ")]),_c('div',{staticClass:"th min-w-40"},[_vm._v(" Waktu Pick Up Maks (Menit) ")]),_c('div',{staticClass:"th w-auto"},[_vm._v(" Penalti ")])])}]

export { render, staticRenderFns }